exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-privacidad-tsx": () => import("./../../../src/pages/aviso-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-privacidad-tsx" */),
  "component---src-pages-conocenos-js": () => import("./../../../src/pages/conocenos.js" /* webpackChunkName: "component---src-pages-conocenos-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cotizador-[property]-tsx": () => import("./../../../src/pages/cotizador/[property].tsx" /* webpackChunkName: "component---src-pages-cotizador-[property]-tsx" */),
  "component---src-pages-cotizador-index-tsx": () => import("./../../../src/pages/cotizador/index.tsx" /* webpackChunkName: "component---src-pages-cotizador-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-propiedades-[category]-tsx": () => import("./../../../src/pages/propiedades/[category].tsx" /* webpackChunkName: "component---src-pages-propiedades-[category]-tsx" */),
  "component---src-pages-propiedades-index-tsx": () => import("./../../../src/pages/propiedades/index.tsx" /* webpackChunkName: "component---src-pages-propiedades-index-tsx" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-terminos-condiciones-tsx": () => import("./../../../src/pages/terminos-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-condiciones-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-properties-list-tsx": () => import("./../../../src/templates/PropertiesList.tsx" /* webpackChunkName: "component---src-templates-properties-list-tsx" */),
  "component---src-templates-property-details-tsx": () => import("./../../../src/templates/PropertyDetails.tsx" /* webpackChunkName: "component---src-templates-property-details-tsx" */)
}

